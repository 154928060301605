<template>
    <div class="main">
        <div class="banner"></div>
        <div class="box">
            <div class="nickNameAndTime">
                <div class="nickName">{{ nickName }}</div>
                <div class="nickName">活动截至时间：{{ active.deadline }}</div>
            </div>
            <div class="line"></div>
            <div class="aa">
                <div class="aaa">在线时长</div>
                <div class="aaa">在线目标</div>
            </div>
            <div class="bb">
                <div class="bbb1">{{ record.online_time }}分钟</div>
                <div v-if="record.online_time < 180" class="bbb2">180分钟</div>
                <div v-else class="bbb3">180分钟</div>
            </div>
            <div class="aa">
                <div class="aaa">当前接听率</div>
                <div class="aaa">接听率目标</div>
            </div>
            <div class="bb">
                <div v-if="record.called_num == 0" class="bbb1">0%</div>
                <div v-if="record.called_num > 0" class="bbb1">{{ (record.pick_call_num / record.called_num * 100) | numFilter }}%</div>
                <div v-if="record.called_num > 0 && record.pick_call_num / record.called_num>=0.8" class="bbb3">80%</div>
                <div v-else class="bbb2">80%</div>
            </div>
            <div class="aa">
                <div class="aaa">当前消息回复率</div>
                <div class="aaa">目标消息回复率</div>
            </div>
            <div class="bb">
                <div v-if="record.receive_msg_num == 0" class="bbb1">0%</div>
                <div v-if="record.receive_msg_num > 0" class="bbb1">{{ (record.reply_msg_num / record.receive_msg_num * 100) |numFilter }}%</div>
                <div v-if="record.receive_msg_num > 0 && record.reply_msg_num / record.receive_msg_num>=0.8" class="bbb3">80%</div>
                <div v-else class="bbb2">80%</div>
            </div>
            <div class="aa">
                <div class="aaa">当前聊天人数</div>
                <div class="aaa">目标聊天人数</div>
            </div>
            <div class="bb">
                <div class="bbb1">{{ record.reply_msg_num }}</div>
                <div v-if="record.reply_msg_num<40" class="bbb2">40</div>
                <div v-else class="bbb3">40</div>
            </div>
        </div>
        <div class="ruleTitle">活动规则</div>
        <div class="rule">
            每天晚上9点到第二天凌晨1点时间段内：<br>
            1. 在线时长需达到180分钟<br>
            2. 接听率不得低于80%（响铃5秒内挂断的不计入，通话中的拨号不计入）<br>
            3. 消息回复率不得低于80%<br>
            4. 聊天人数不得低于40人（含回复消息和打招呼）<br>
            5. 不得开启勿扰模式和隐身模式<br>
            全部达标的参与者，将获得10000钻石的奖励，于第二天9点存入收益账户
            <br>
            平台保留最终解释权
        </div>
    </div>
</template>

<script>
import { doRequest } from '@/utils/request'

export default {
    data() {
        return {
            nickName: '',
            active: {},
            record: {},
        }
    },
    filters: {
        numFilter (value) {
            let realVal = ''
            if (!isNaN(value) && value!== '') {
                // 截取当前数据到小数点后两位
                realVal = parseFloat(value).toFixed(0)
            } else {
                realVal = '--'
            }
            return realVal
        }
    },
    methods: {
        initData() {
            doRequest('/encourageActive/myPerformance', {}, this.$route.query).then(res => {
                this.nickName = res.nick_name
                this.active = res.active
                this.record = res.performance
                console.log(res)
            })
        }
    },
    mounted() {
        this.initData()
    },
}
</script>

<style scoped>
.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 375px;
}

.banner {
    width: 375px;
    height: 281.55px;
    background: url('../../assets/performance.png');
    background-repeat: no-repeat ;
    background-position: center;
    background-size: 100% ;
}

.box {
    width: 346px;
    height: 360px;
    margin-top: -70px;
    padding-bottom: 36.25px;

    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */

    background: #FFFFFFFF;
    border-radius: 5px;
    box-shadow: 0px 2px 8px 0px rgba(190, 190, 190, 0.45);
}

.box .nickNameAndTime {
    display: flex;
    height: 47.45px;
    justify-content: space-around;
    align-items: center;
}

.box .nickNameAndTime .nickName {
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.box .nickNameAndTime .time {
    font-size: 11px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #999999;
}

.box .line {
    width: 346px;
    height: 1px;
    background: #F4F4F4;
    border-radius: 0px 0px 1px 1px;
}

.box .aa {
    margin-top: 33.1px;
    display: flex;
    height: 13.5px;
    justify-content: space-around;
    align-items: center;
}

.box .aa .aaa {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
}

.box .bb {
    margin-top: 5px;
    margin-left: 15.25px;
    width: 314px;
    height: 35px;
    background: #F5F5F5;

    display: flex;
    justify-content: space-around;
    align-items: center;
}

.box .bb .bbb1 {
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
}

.box .bb .bbb2 {
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #F9518C;
}

.box .bb .bbb3 {
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #05DF95;
}

.ruleTitle {
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 23px;
    margin-top: 31.64px;
    margin-left: 31.5px;
    align-self: flex-start;
}

.rule {
    width: 299px;
    word-break:break-all;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 23px;
    margin-top: 15.5px;
    margin-left: 31.5px;
    align-self: flex-start;
}
</style>